<template>
  <FiltersComponent :filterProps="filter">
    <div class="report-constructor-tab__filter-buttons">
      <ButtonStock title="Сформировать отчет" @click="exportExcel" :disabled="exportInProgress" />
    </div>
  </FiltersComponent>

  <ModalInfo
    v-model="isOpenModalInfo"
    :titleModal="titleModal"
    :textModal="textModal"
    :modalIconName="modalIconName"
    @closeModal="closeModalInfo"
  />
</template>

<script>
  import ModalInfo from '@/common/components/redesigned/ModalInfo.vue';
  import Constants from '@/common/constants';
  import modalInfo from '@/common/mixins/modalInfo';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';

  import Api from '../../../api/restrictmeasures';
  import { DEFAULT_FILTER_VALUES, MODAL_INFO_MESSAGES } from '../../../utils/consts';
  import FiltersComponent from '../../FiltersComponent.vue';

  export default {
    components: { FiltersComponent, ButtonStock, ModalInfo },

    props: {
      filterProps: {
        type: Object,
        required: true,
      },
    },
    mixins: [modalInfo],

    data() {
      return {
        exportInProgress: false,
        filter: this.filterProps,
      };
    },

    methods: {
      exportExcel() {
        if (JSON.stringify(this.filter) === JSON.stringify(DEFAULT_FILTER_VALUES)) {
          this.titleModal = 'Ошибка';
          this.textModal = MODAL_INFO_MESSAGES.EMPTY_DATA;
          this.modalIconName = 'errorIcon';
          this.isOpenModalInfo = true;
        } else {
          this.exportInProgress = true;
          this.preprocessFilter();
          Api.exportExcel(this.filter)
            .then(() => {})
            .catch((e) => {
              if (e.response.status === 400) {
                this.titleModal = 'Ошибка';
                this.textModal = MODAL_INFO_MESSAGES.NO_DATA;
                this.modalIconName = 'errorIcon';
                this.isOpenModalInfo = true;
              } else {
                console.error(e);
              }
            });
          this.exportInProgress = false;
        }
      },

      preprocessFilter() {
        this.filter.industryIds = [];
        this.filter.tnvedIndustryIds = [];
        this.filter.aggregateIndustryIds.forEach((item) => {
          if (item) {
            let valueArr = item.split(';');
            if (valueArr[1] === String(Constants.tradeItemType.tnved))
              this.filter.tnvedIndustryIds.push(Number(valueArr[0]));
            else if (valueArr[1] === String(Constants.tradeItemType.okved))
              this.filter.industryIds.push(Number(valueArr[0]));
          }
        });
      },
    },
  };
</script>

<style lang="scss" scoped>
  .report-constructor-tab {
    &__filter-buttons {
      margin-bottom: 80px;

      button {
        padding: 12px 16px;
      }
    }
  }

  @media (max-width: $desktop) {
    .report-constructor-tab {
      &__filter-buttons {
        margin-bottom: 60px;
      }
    }
  }

  @media (max-width: $mobile) {
    .report-constructor-tab {
      &__filter-buttons {
        margin-bottom: 60px;
        button {
          width: 100%;
        }
      }
    }
  }
</style>
