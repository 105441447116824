<template>
  <div class="container-page big">
    <div class="d-flex flex-row justify-content-between analytics__title-container">
      <p class="title-inner">Аналитика</p>
      <div class="d-flex flex-row">
        <ButtonStock class="mr-2" title="Создать" @click="editCard(0)" v-if="canEdit" />
      </div>
    </div>

    <tabs>
      <tab name="Конструктор отчетов" class="analytics__constructor-container" :selected="true">
        <ReportConstructorTab :filterProps="filter" />
      </tab>

      <tab name="Шаблоны отчетов" :selected="false">
        <div class="btns-container">
          <!-- - «Снятые барьеры»;
                    - «Выявленные барьеры»;
                    - «Реестр ограничительных мер»;
                    - «Статистика ограничительных мер». -->
          <div class="btn-export__container col-6 mb-2">
            <ButtonStock class="btn-export" title="Снятые барьеры" @click="showExportStatisticsDialog(1)" />
          </div>
          <div class="btn-export__container col-6 mb-2">
            <ButtonStock class="btn-export" title="Выявленные барьеры" @click="showExportStatisticsDialog(2)" />
          </div>
          <div class="btn-export__container col-6 mb-2">
            <ButtonStock class="btn-export" title="Реестр ограничительных мер" @click="showExportStatisticsDialog(3)" />
          </div>
          <div class="btn-export__container col-6 mb-2">
            <ButtonStock
              class="btn-export"
              title="Статистика ограничительных мер"
              @click="showExportStatisticsDialog(4)"
            />
          </div>
          <div class="btn-export__container col-6 mb-2">
            <ButtonStock class="btn-export" title="Тепловая карта мира" @click="goToPublic" />
          </div>

          <!-- <button-stock title="Экспорт отчета" @click="showExportStatisticsDialog" /> -->
        </div>
      </tab>
      <tab name="Статистика торговых ограничений" :selected="false">
        <RestrictionStatisticTab />
      </tab>
    </tabs>

    <ModalComponent
      title="Экспорт отчета"
      @close="dialogStatisticsWordState = false"
      v-model="dialogStatisticsWordState"
    >
      <div class="form__body">
        <div class="form__body-inputBlock modal-date-container mb-3 pb-3">
          <DateComponent
            :label="currentDialogId == 4 ? 'Дата' : 'Дата c'"
            v-model="statisticsDateFrom"
            type="date"
            required
          />
          <DateComponent v-if="currentDialogId != 4" label="по" v-model="statisticsDateTo" type="date" required />
        </div>
        <div class="form__body-inputBlock mb-3 pb-3" v-if="currentDialogId != 4">
          <ComponentInput
            label="Страна"
            placeholder="Укажите страну"
            :select2Settings="select2SettingsMultiple"
            :select2Options="statisticsCountryList"
            v-model="statisticsCountryIds"
            type="select2"
            multiple
          />
        </div>
        <div class="form__body-inputBlock">
          <ButtonStock title="Выгрузить" @click="exportWordStatistics" />
        </div>
      </div>
    </ModalComponent>
  </div>
</template>

<script>
  import ComponentInput from '@/common/components/ComponentInput';
  import DateComponent from '@/common/components/dateComponent';
  import tabs from '@/common/components/redesigned/Tabs/Tab.vue';
  import tab from '@/common/components/redesigned/Tabs/TabItem.vue';
  import Constants from '@/common/constants';
  import security from '@/common/mixins/security';
  import runtimeConfig from '@/common/runtime-config';
  import Utils from '@/common/utils';

  import ButtonStock from '@/components/buttons/ButtonStock.vue';
  import ModalComponent from '@/components/modals/ModalComponent.vue';

  import Api from '../api/restrictmeasures';
  import ReportConstructorTab from '../components/analytics/reportConstructorTab/ReportConstructorTab.vue';
  import RestrictionStatisticTab from '../components/analytics/statisticTab/RestrictionStatisticTab.vue';
  import { DEFAULT_FILTER_VALUES } from '../utils/consts';

  export default {
    components: {
      ButtonStock,
      ComponentInput,
      DateComponent,
      ModalComponent,
      tabs,
      tab,
      RestrictionStatisticTab,
      ReportConstructorTab,
    },

    mixins: [security],

    data() {
      return {
        filter: { ...DEFAULT_FILTER_VALUES },
        select2SettingsMultiple: Constants.select2SettingsMultiple,
        statisticsDateFrom: null,
        statisticsDateTo: null,
        statisticsCountryIds: null,
        statisticsCountryList: [{ id: 0, text: 'Все страны' }],
        allCountries: false,
        dialogStatisticsWordState: false,
        currentDialogId: null,
        canEdit: false,
      };
    },

    created() {
      this.checkPermission(Constants.Permissions.RestrictionMeasureCreate).then((response) => {
        this.canEdit = response.data;
      });
      this.loadDictionaries();
    },

    methods: {
      editCard(id) {
        this.$router.push({
          name: 'RestrictionMeasureEditPage',
          params: { id: id, action: 'edit' },
        });
      },
      loadDictionaries() {
        Utils.loadSelectOptions('countryList', this.statisticsCountryList, true);
      },
      showExportStatisticsDialog(dialogId) {
        this.currentDialogId = dialogId;
        this.statisticsCountryIds = ['0'];
        this.statisticsDateFrom = null;
        this.statisticsDateTo = null;
        this.dialogStatisticsWordState = true;
      },
      exportWordStatistics() {
        this.dialogStatisticsWordState = false;
        let fileName = '';
        switch (this.currentDialogId) {
          case 1:
            fileName = 'RemovedBarriers.docx';
            break;
          case 2:
            fileName = 'IdentifiedBarriers.docx';
            break;
          case 3:
            fileName = 'RestrictionMeasureRegistry.docx';
            break;
          case 4:
            fileName = 'StatisticalReport.docx';
            break;
          default:
            break;
        }
        Api.exportWord(
          {
            dateFrom: this.statisticsDateFrom,
            dateTo: this.statisticsDateTo,
            countryIds: this.statisticsCountryIds,
            dialogId: this.currentDialogId,
          },
          fileName,
        );
      },
      goToPublic() {
        window.open(runtimeConfig.publicBaseUrl + 'market-access', '_blank');
      },
    },
    watch: {
      statisticsCountryIds: {
        handler: function (value) {
          if (!value) return;
          if (this.statisticsCountryIds.length > 1 && this.statisticsCountryIds.includes('0'))
            this.statisticsCountryIds = ['0'];

          this.allCountries = this.statisticsCountryIds.includes('0');
        },
      },
    },
  };
</script>

<style lang="scss" scoped>
  .container-page-rktv {
    padding: 0;
  }
  .btns-container {
    margin: 40px 0;
    display: flex;
    flex-wrap: wrap;
  }
  .btn-export {
    width: 100%;
  }

  .modal-date-container::v-deep {
    .v3dp__popout {
      right: unset;
      position: fixed !important;
      z-index: 11110 !important;
    }
  }

  .analytics {
    &__filter-buttons {
      margin-bottom: 80px;

      button {
        padding: 12px 16px;
      }
    }

    &__title-container {
      margin-bottom: 32px;
    }
  }

  @media (max-width: $desktop) {
    .btns-container {
      flex-wrap: wrap;
      button {
        margin-bottom: 6px;
      }
    }
    .constructor-col {
      max-width: initial;
    }

    .analytics {
      &__filter-buttons {
        margin-bottom: 60px;
      }

      &__title-container {
        margin-bottom: 24px;
      }
    }
  }

  @media (max-width: $laptop) {
    .analytics {
      &__filter-buttons {
        margin-bottom: 40px;

        button {
          width: 100%;
        }
      }
    }
  }

  .modal-title {
    display: flex;
    gap: 16px;
    font: $fira-32;
    font-weight: 500;

    &_pre-wrap {
      white-space: pre-wrap;
    }
  }

  @media (max-width: $mobile) {
    .analytics {
      &__filter-buttons {
        button {
          width: 100%;
        }
      }

      &__title-container {
        margin-bottom: 12px;
      }
    }
  }

  @media (max-width: 375px) {
    .btns-container {
      button {
        width: 100%;
        &:nth-of-type(2) {
          margin-left: 0px;
          margin-right: 0px;
        }
      }
    }
  }
</style>
